var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"mb-1",attrs:{"xs6":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1),_c('v-spacer'),_c('v-flex',{staticClass:"py-2 text-right",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"outlined":"","small":""},on:{"click":_vm.newProject}},[_vm._v(" "+_vm._s(_vm.$t("new project"))+" ")])],1)],1),_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[(!_vm.selected && _vm.ownersBusinesses.length)?_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","solo":"","label":_vm.$t('search business'),"item-text":"businessName","item-value":"id","items":_vm.ownersBusinesses},on:{"change":_vm.onSelectShop},model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1):_vm._e(),_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","label":_vm.$t('search projects')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white"},on:{"click":function($event){return _vm.getProjects()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("refresh table")))])])],1),_c('v-flex',{staticClass:"text-right pa-1",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.projectDueDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.projectDueDate=$event},"update:return-value":function($event){_vm.projectDueDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","dense":"","in":"","solo":""},model:{value:(_vm.projectDueDate),callback:function ($$v) {_vm.projectDueDate=$$v},expression:"projectDueDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.projectDueDate),callback:function ($$v) {_vm.projectDueDate=$$v},expression:"projectDueDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.projectDueDate);
              _vm.dateFilter();}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"text-right mb-4",attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},_vm._l((_vm.menu),function(button){return _c('v-btn',{key:button.title,staticClass:"mx-1 mt-1",attrs:{"color":button.selected
            ? _vm.$store.state.secondaryColor
            : _vm.$store.state.primaryColor,"x-small":"","outlined":""},on:{"click":function($event){return _vm.sortBy(button.action)}}},[_c('span',{staticClass:"caption text-lowercase"},[_vm._v(_vm._s(button.title))])])}),1)],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"loading-text":"loading...","items":_vm.filteredProjects,"sort-by":_vm.sortByDate,"sort-desc":_vm.sortDesc,"search":_vm.search,"items-per-page":20},on:{"update:sortBy":function($event){_vm.sortByDate=$event},"update:sort-by":function($event){_vm.sortByDate=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.businessId",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBusinessName(item.businessId))+" ")]}},{key:"item.assignedTo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmployeeName(item.assignedTo))+" ")]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.status == 'inProgress')?_c('v-btn',{staticStyle:{"cursor":"context-menu"},attrs:{"small":"","text":"","rounded":"","color":_vm.$store.state.secondaryColor,"dark":""}},[_vm._v(_vm._s(_vm.$t("in progress"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-progress-check")])],1):(item.status == 'overdue')?_c('v-btn',{staticStyle:{"cursor":"context-menu"},attrs:{"small":"","text":"","rounded":"","color":"red","dark":""}},[_vm._v(_vm._s(_vm.$t("overdue"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-alarm-check")])],1):_c('v-btn',{staticStyle:{"cursor":"context-menu"},attrs:{"small":"","text":"","rounded":"","color":"success","dark":""}},[_vm._v(_vm._s(_vm.$t("done"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)]}},{key:"item.dueDate",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dueDate))+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.primaryColor},on:{"click":function($event){return _vm.editProject(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":"","left":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){return _vm.viewProject(item.id)}}},[_vm._v("visibility")]),_c('v-icon',{attrs:{"small":"","left":"","color":"red"},on:{"click":function($event){return _vm.deleteDialog(item.id, item.projectTitle)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1),_c('v-snackbar',{attrs:{"top":"","center":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("account deleted successfully"))+"!")])]),_c('v-snackbar',{attrs:{"top":"","center":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not delete project. Try again later")))])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delDialog),callback:function ($$v) {_vm.delDialog=$$v},expression:"delDialog"}},[_c('v-card',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"my-5",attrs:{"outlined":"","large":"","fab":"","color":"red darken-4"}},[_c('v-icon',[_vm._v("warning")])],1)],1),_c('v-card-text',{staticClass:"text-center"},[_c('p',[_vm._v(_vm._s(_vm.$t("are you sure"))+"?")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("do you really want to delete"))+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.projectTitle))]),_vm._v(" "+_vm._s(_vm.$t("project"))+"? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"text":"","small":""},on:{"click":function($event){_vm.delDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.$store.state.primaryColor,"text":"","loading":_vm.loading,"small":""},on:{"click":_vm.deleteProjects}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }