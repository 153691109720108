<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newProject"
        >
          {{ $t("new project") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex
        xs12
        sm4
        md4
        lg4
        v-if="!selected && ownersBusinesses.length"
        class="py-1 px-1"
      >
        <v-select
          dense
          solo
          class="caption"
          :label="$t('search business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search projects')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="white" @click="getProjects()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="projectDueDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="projectDueDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="projectDueDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(projectDueDate);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12 class="text-right mb-4">
        <!--sort buttons-->

        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          x-small
          outlined
          class="mx-1 mt-1"
          v-for="button in menu"
          :key="button.title"
          @click="sortBy(button.action)"
        >
          <span class="caption text-lowercase">{{ button.title }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          loading-text="loading..."
          :items="filteredProjects"
          :sort-by.sync="sortByDate"
          :sort-desc.sync="sortDesc"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>

          <template v-slot:[`item.assignedTo`]="{ item }">
            {{ getEmployeeName(item.assignedTo) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-btn
              small
              text
              rounded
              :color="$store.state.secondaryColor"
              dark
              v-if="item.status == 'inProgress'"
              style="cursor: context-menu"
              >{{ $t("in progress") }}
              <v-icon right>mdi-progress-check</v-icon></v-btn
            >
            <v-btn
              small
              text
              rounded
              color="red"
              dark
              v-else-if="item.status == 'overdue'"
              style="cursor: context-menu"
              >{{ $t("overdue") }} <v-icon right>mdi-alarm-check</v-icon></v-btn
            >
            <v-btn
              small
              text
              rounded
              color="success"
              dark
              v-else
              style="cursor: context-menu"
              >{{ $t("done") }} <v-icon right>mdi-check</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            {{ formatDate(item.dueDate) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              left
              :color="$store.state.primaryColor"
              @click="editProject(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              left
              :color="$store.state.secondaryColor"
              @click="viewProject(item.id)"
              >visibility</v-icon
            ><v-icon
              small
              left
              color="red"
              @click="deleteDialog(item.id, item.projectTitle)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("account deleted successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete project. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete") }}
            <span class="font-weight-bold">{{ projectTitle }}</span>
            {{ $t("project") }}?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="deleteProjects"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { format } from "date-fns";
import db from "@/plugins/fb";
export default {
  data: () => ({
    projectDueDate: format(new Date(), "yyyy-MM-dd"),
    loading: false,
    delDialog: false,
    modal: false,
    sortDesc: true,
    sortByDate: "dateTime",
    search: "",
    snackSuccess: false,
    snackError: false,
    projectId: "",
    projectTitle: "",
    filteredProjects: [],
    businesses: [],
    employees: [],
    projects: [],
    selectedShop: "",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    menu() {
      return [
        {
          title: this.$t("all"),
          action: "all",
          selected: true,
        },
        {
          title: this.$t("done"),
          action: "done",
          selected: false,
        },
        {
          title: this.$t("overdue"),
          action: "overdue",
          selected: false,
        },
        {
          title: this.$t("in progress"),
          action: "inProgress",
          selected: false,
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("project title"),
          value: "projectTitle",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("assigned to"),
          value: "assignedTo",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("status"),
          value: "status",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },

        {
          text: this.$t("due date"),
          value: "dueDate",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("description"),
          value: "description",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getProjects();
    this.getEmployees();
  },

  methods: {
    getProjects() {
      this.loading = true;
      this.projects = [];

      this.menu[0].selected = true;
      this.menu[1].selected = false;
      this.menu[2].selected = false;
      this.menu[3].selected = false;
      if (this.selected) {
        db.collection("projects")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.projects.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.projects.filter(
                (item) => item.dueDate == this.$store.state.todaysDate
              );
              this.filteredProjects = filtered;
            } else {
              this.filteredProjects = this.projects;
            }

            //keep projects in vuex store
            this.$store.commit("SET_PROJECTS", this.projects);
            this.loading = false;
          });
      } else {
        db.collection("projects")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.projects.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.projects.filter(
                (item) => item.dateTime == this.$store.state.todaysDate
              );
              this.filteredProjects = filtered;
            } else {
              this.filteredProjects = this.projects;
            }
            //keep projects in vuex store
            this.$store.commit("SET_PROJECTS", this.projects);
            this.loading = false;
          });
      }
    },
    getEmployees() {
      this.employees = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
          });
      }
    },
    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },
    getEmployeeName(id) {
      let name = this.employees.find((item) => item.employeeId == id);

      let employeeName = name.names.first + " " + name.names.last;
      return employeeName;
    },

    onSelectShop() {
      this.loading = true;
      this.projects = [];
      db.collection("projects")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.projects.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          if (
            this.$store.state.todaysDate &&
            this.$store.state.todaysDate.length != 0
          ) {
            let filtered = this.projects.filter(
              (item) => item.dateTime == this.$store.state.todaysDate
            );
            this.filteredProjects = filtered;
          } else {
            this.filteredProjects = this.projects;
          }
          //keep projects in vuex store
          this.$store.commit("SET_PROJECTS", this.projects);
          this.loading = false;
        });
    },

    deleteDialog(projectId, projectTitle) {
      this.projectId = projectId;
      this.projectTitle = projectTitle;
      this.delDialog = true;
    },
    deleteProjects() {
      this.loading = true;
      db.collection("projects")
        .doc(this.projectId)
        .delete()
        .then(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackSuccess = true;
          this.getProjects();
        })
        .catch(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackError = true;
        });
    },

    dateFilter() {
      let filtered = this.projects.filter(
        (item) =>
          format(item.dueDate.toDate(), "yyyy-MM-d") == this.projectDueDate
      );
      this.filteredProjects = filtered;
    },
    sortBy(prop) {
      var new_array;
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        //get all orders from vuex store
        this.projects = this.$store.state.projects;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.projects.filter(
            (item) => item.dueDate == this.$store.state.todaysDate
          );
          this.filteredProjects = filtered;
        } else {
          this.filteredProjects = this.projects;
        }
      } else if (prop == "done") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.projects = this.$store.state.projects;

        new_array = this.projects.filter((element) => element.status == "done");

        this.projects = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.projects.filter(
            (item) => item.dueDate == this.$store.state.todaysDate
          );
          this.filteredProjects = filtered;
        } else {
          this.filteredProjects = this.projects;
        }
      } else if (prop == "overdue") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.menu[3].selected = false;
        this.projects = this.$store.state.projects;

        new_array = this.projects.filter(
          (element) => element.status == "overdue"
        );

        this.projects = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.projects.filter(
            (item) => item.dueDate == this.$store.state.todaysDate
          );
          this.filteredProjects = filtered;
        } else {
          this.filteredProjects = this.projects;
        }
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = true;
        this.projects = this.$store.state.projects;

        new_array = this.projects.filter(
          (element) => element.status == "inProgress"
        );

        this.projects = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.projects.filter(
            (item) => item.dueDate == this.$store.state.todaysDate
          );
          this.filteredProjects = filtered;
        } else {
          this.filteredProjects = this.projects;
        }
      }
    },

    formatDate(val) {
      return format(val.toDate(), "dd MMMM, yyyy HH:mm:a");
    },
    editProject(params) {
      this.$router.push({ name: "EditProject", params: { id: params } });
    },

    viewProject(params) {
      this.$router.push({ name: "ViewProject", params: { id: params } });
    },

    newProject() {
      this.$router.push({
        name: "NewProject",
      });
    },
    goBack() {
      this.$router.push({
        name: "DashboardAccount",
      });
    },
  },
};
</script>
